/**
 * maintenance模块接口列表
 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
// 根据需求是否导入qs模块
import qs from "qs";
const maintenance = {
  personPages(params) {
    // 获取员工状态
    return axios.post(base.dl + "maintenancePersonnel/pages", params);
  },
  personPosition(params) {
    // 获取员工动向
    if (params.pageNumber) {
      return axios.post(
        base.dl +
          "maintenancePersonnel/location?currentState=" +
          params.currentState +
          "&pageNumber=" +
          params.pageNumber +
          "&pageSize=" +
          params.pageSize
      );
    } else {
      return axios.post(
        base.dl +
          "maintenancePersonnel/location?currentState=" +
          params.currentState
      );
    }
  },
  personDownload(params) {
    // 导出员工状态
    return axios.post(base.dl + "maintenancePersonnel/download", params, {
      responseType: "blob",
    });
  },
  handbillPages(params) {
    // 工单管理
    return axios.post(
      base.bl +
        "flow/maintenance/list?page=" +
        params.page +
        "&pageSize=" +
        params.pageSize,
      params
    );
  },
  listBillPages(params) {
    // 工单查询
    return axios.post(
      base.bl +
        "flow/maintenance/listByDevice?page=" +
        params.page +
        "&pageSize=" +
        params.pageSize,
      params
    );
  },
  getBillByDate(params) {
    // 当日工单统计-即将超时工单
    return axios.get(base.bl + "flow/maintenance/today/moribund", {
      params: params,
    });
  },
  getBillSumByDate() {
    // 当日工单统计-数量信息
    return axios.get(base.bl + "flow/maintenance/today/statistics");
  },
  personsMen() {
    // 新增工单，获取维保人员
    return axios.post(base.sq + "persons/men");
  },
  getAllEle(params) {
    // 新增工单，获取所有电梯  电梯工号补全
    return axios.get(base.dl + "elevator/elevator_list", {
      params: params,
    });
  },
  addBillOne(params) {
    // 新增工单 稍后派发2 立即派发1
    return axios.post(base.bl + "flow/maintenance", params);
  },
  ipcWarning(params,id) {
    // 新增工单 稍后派发2 立即派发1
    return axios.put(base.bl + `ipcWarning/${id}`, params);
  },
  sendBill(id) {
    // 派发工单
    return axios.post(base.bl + "flow/maintenance/sendBill/" + id);
  },
  handBillsResign(id, params) {
    // 编辑工单，改派工单
    return axios.post(base.bl + "flow/maintenance/edit/" + id, params);
  },
  handbillsStatus(id) {
    // 工单处理过程
    return axios.get(
      base.bl + "flow/maintenance/getBillByIdForStatus/" + id + "/CN"
    );
  },
  getBillDetail(id) {
    // 老工单详情
    return axios.get(base.bl + "flow/maintenance/getBillById/" + id);
  },
  getBillReport(id) {
    // 工单报告
    return axios.get(base.bl + "flow/maintenance/generateWorkBillView/" + id);
  },
  getStatusDetail(id) {
    // 工单印章
    return axios.get(base.bl + "flow/maintenance/status/" + id);
  },
  getResult(id) {
    // 核查条件-审核配置-工单自动审核提示
    return axios.get(base.dl + "workBillInspectConfig/result/" + id);
  },
  setInspectReason(id, params) {
    // 不合格原因
    return axios.post(
      base.bl +
        "flow/maintenance/inspectResult/" +
        id +
        "?inspectResult=" +
        params.inspectResult
    );
  },
  setInspection(id, params) {
    // 核查
    return axios.post(
      base.bl +
        "flow/maintenance/inspect/" +
        id +
        "?inspectStatus=" +
        params.inspectStatus
    );
  },
  setInspectResult(id, params) {
    // 填写核查结果
    return axios.post(
      base.bl +
        "flow/maintenance/inspectResult/" +
        id +
        "?inspectResult=" +
        params.inspectResult
    );
  },
  deleteHandBills(id) {
    // 删除工单
    return axios.delete(base.bl + "flow/maintenance/delete/" + id);
  },
  exportBillOne(id) {
    // 导出单个工单
    return axios.get(base.bl + "flow/maintenance/report/" + id, {
      responseType: "blob",
    });
  },
  exportWorkBills(params) {
    // 导出工单
    return {
      url: base.bl + "flow/maintenance/exportExcel",
      params: params,
    };
  },
  getBillPerson() {
    // 新增工单获取维保人员
    return axios.get(base.dl + "maintenancePersonnel/men");
  },
  getIssuesData(params) {
    // 未决事宜
    return axios.post(
      base.bl +
        "flow/maintenance/searchPendingIssue?pageNumber=" +
        params.pageNumber +
        "&pageSize=" +
        params.pageSize,
      params
    );
  },
  contractPages(params) {
    // 查询合同
    return axios.post(base.dl + "contracts/pages", params);
  },
  contractOne(id) {
    // 查询单个合同
    return axios.get(base.dl + "contracts/data/maintain/" + id);
  },
  editContractOne(id, params) {
    // 修改保养合同
    return axios.post(base.dl + "contracts/edit/maintain/" + id, params);
  },
  areaAttribute() {
    // 区域属性
    return axios.get(base.dl + "contracts/areaAttribute");
  },
  getManagerData(name) {
    // 主管人员
    return axios.post(base.dl + "contracts/manager?man=" + name);
  },
  contractBills(id, params) {
    // 合同收款记录
    return axios.get(base.dl + "contracts/data/payable/" + id, {
      params: params,
    });
  },
  addContractColl(id, params) {
    // 新增合同收款记录
    return axios.post(base.dl + "contracts/add/collect/" + id, params);
  },
  addContractBills(id, params) {
    // 新增合同收款记录
    return axios.post(base.dl + "contracts/add/bill/" + id, params);
  },
  elevatorBill(id, ids, params) {
    // 单个电梯开票记录
    return axios.get(base.dl + "contracts/data/bill/" + id + "/" + ids, params);
  },
  elevatorCollect(id, ids, params) {
    // 单个电梯收款记录
    return axios.get(
      base.dl + "contracts/data/collect/" + id + "/" + ids,
      params
    );
  },
  delBillOrCollect(id, type) {
    // 删除开票/收款记录
    return axios.delete(base.dl + "contracts/bills/" + id + "/" + type);
  },
  editBill(params) {
    // 编辑开票记录
    return axios.put(base.dl + "contracts/edit/bill", params);
  },
  editCollect(params) {
    // 编辑收款记录
    return axios.put(base.dl + "contracts/edit/collect", params);
  },
  getContractEle(id, params) {
    // 合同中的电梯列表
    return axios.get(base.dl + "contracts/data/maintain/elevator/" + id, {
      params: params,
    });
  },
  getContractOtherEle(id, params) {
    // 合同中的电梯列表
    return axios.post(
      base.dl +
        "contracts/pages/elevators/" +
        id +
        "?eleNumber=" +
        params.eleNumber +
        "&pageNumber=" +
        params.pageNumber +
        "&pageSize=" +
        params.pageSize +
        "&projectName=" +
        params.projectName
    );
  },
  delContractEle(eleId, upkeepId) {
    // 删除合同中电梯
    return axios.delete(
      base.dl + "contracts/elevator/" + eleId + "/" + upkeepId
    );
  },
  addContractEle(id, params) {
    // 添加电梯到合同
    return axios.post(
      base.dl + "contracts/addElevator/" + id,
      qs.stringify(params)
    );
  },
  getContractRenew(id, params) {
    // 续签记录
    return axios.get(base.dl + "contracts/data/maintain/renewal/" + id, params);
  },
  exportFinance(params) {
    // 导出合同财务相关数据
    return axios.post(
      base.dl + "contracts/downloads/finance",
      qs.stringify(params),
      {
        responseType: "blob",
      }
    );
  },
  getOtherContract(id) {
    // 获取非保养合同详情
    return axios.get(base.dl + "contracts/data/other/" + id);
  },
  editOtherContract(id, params) {
    // 编辑非保养合同
    return axios.put(base.dl + "contracts/edit/other/basic/" + id, params);
  },
  getOtherBill(id, params) {
    // 查询非保养合同开票信息
    return axios.get(base.dl + "contracts/data/bills/" + id, {
      params: params,
    });
  },
  addOtherBill(id, params) {
    // 添加非保养合同开票信息
    return axios.put(base.dl + "contracts/bills/" + id, params);
  },
  getOtherData(id, params) {
    // 关联保养合同列表
    return axios.get(base.dl + "contracts/data/detail/" + id, {
      params: params,
    });
  },
  searchOtherData(id, params) {
    // 搜索保养合同列表
    return axios.get(base.dl + "contracts/page/" + id, {
      params: params,
    });
  },
  addOtherData(id, params) {
    // 关联保养合同到非保养合同
    return axios.post(base.dl + "contracts/orders/" + id, qs.stringify(params));
  },
  delContract(id, params) {
    // 关联保养合同到非保养合同
    return axios.delete(base.dl + "contracts/orders/" + id, {
      params: params,
    });
  },
  editContractBill(params) {
    // 关联保养合同到非保养合同
    return axios.post(base.dl + "contracts/editableDataBillNew", params);
  },
  getItemName(params) {
    // 查询可用的项目名称
    return axios.post(base.dl + "contracts/items", qs.stringify(params));
  },
  getMainPerson() {
    // 维保人员
    return axios.get(base.dl + "maintenanceBath/bath/person");
  },
  getMainElevator(params) {
    // 新增批次  电梯列表
    return axios.get(base.dl + "maintenanceBath/elevators", {
      params: params,
    });
  },
  addPlanBath(id, params) {
    // 新增维保批次
    return axios.post(
      base.dl + "maintenanceBath/bathElevatorAdd/" + id,
      params
    );
  },
  getScheduleData(start, end) {
    // 获取保养日程数据
    return axios.get(base.dl + "contracts/schedule/" + start + "/" + end);
  },
  contractExport(params) {
    // 合同导出
    return axios.post(base.dl + "contracts/download/base", params, {
      responseType: "blob",
    });
  },
  addContract(params) {
    // 新增合同
    return axios.post(base.dl + "contracts/one", params);
  },
  isExistContract(params) {
    // 判断合同号是否存在
    return axios.get(base.dl + "contracts/existence/no", {
      params: params,
    });
  },
  getConfigAign(id) {
    // 获取公司下的扫码位置
    return axios.get(base.dl + "maintenanceProjectConfig/signs/" + id);
  },
  addConfigAign(params) {
    // 新增扫码位置
    return axios.post(base.dl + "maintenanceProjectConfig/sign", params);
  },
  editConfigAign(params) {
    // 编辑扫码位置
    return axios.put(base.dl + "maintenanceProjectConfig/sign", params);
  },
  delConfigAign(id) {
    // 删除扫码位置
    return axios.delete(base.dl + "maintenanceProjectConfig/sign/" + id);
  },
  getConfigData(params) {
    // 获取维保配置
    return axios.get(base.dl + "maintenanceProjectConfig/items", {
      params: params,
    });
  },
  addConfigData(params) {
    // 新增维保配置
    return axios.post(base.dl + "maintenanceProjectConfig/item", params);
  },
  editConfigData(params) {
    // 编辑维保配置
    return axios.put(base.dl + "maintenanceProjectConfig/item", params);
  },
  delConfigData(id) {
    // 删除维保配置
    return axios.delete(base.dl + "maintenanceProjectConfig/item/" + id);
  },
  getNotSign(params) {
    //获取未使用的扫码位置
    return axios.get(base.dl + "maintenanceProjectConfig/signs/nc", {
      params: params,
    });
  },
  setItemMode(params) {
    // 单项次使用基础模板
    return axios.post(
      base.dl + "maintenanceProjectConfig/item/mode",
      qs.stringify(params)
    );
  },
  setAllItemMode(params) {
    // 所有项次使用基础模板
    return axios.post(
      base.dl + "maintenanceProjectConfig/items/mode",
      qs.stringify(params)
    );
  },
  exportBills(params) {
    // 导出开票记录
    return axios.post(base.dl + "contracts/download/bills", params, {
      responseType: "blob",
    });
  },
  exportNotBills(id) {
    // 导出开票记录/非保养
    return axios.post(base.dl + "contracts/download/bills/" + id, {
      responseType: "blob",
    });
  },
  exportCollect(params) {
    // 导出收款记录
    return axios.post(base.dl + "contracts/download/proceeds", params, {
      responseType: "blob",
    });
  },
  getBillsPage(params) {
    // 开票记录列表
    return axios.post(base.dl + "contracts/pages/bill", params);
  },
  editBills(id, params) {
    // 编辑开票记录
    return axios.put(base.dl + "contracts/edit/billing/" + id, params);
  },
  addBills(params) {
    // 新增开票记录
    return axios.post(base.dl + "contracts/bills/info", params);
  },
  getCollectingPage(params) {
    // 收款记录列表
    return axios.post(base.dl + "contracts/pages/collection", params);
  },
  addCollect(params) {
    // 新增收款记录
    return axios.post(base.dl + "contracts/collections/info", params);
  },
  editCollects(id, params) {
    // 编辑收款记录
    return axios.put(base.dl + "contracts/edit/collecting/" + id, params);
  },
  getLostRenewPage(params) {
    // 丢失、续签记录
    return axios.post(base.dl + "contracts/pages/renewal_drop", params);
  },
  getInAllElevator(id, params) {
    // 丢失、续签记录内电梯列表
    return axios.get(base.dl + "contracts/elevators/" + id, {
      params: params,
    });
  },
  getAllEleIds(id) {
    // 丢失、续签记录内所有电梯ids
    return axios.get(base.dl + "contracts/elevator/ids/" + id);
  },
  setLose(params) {
    // 丢失合同
    return axios.post(base.dl + "contracts/lose/one", params);
  },
  setRenew(params) {
    // 续签合同
    return axios.post(base.dl + "contracts/renewal/one", params);
  },
  getBathPage(params) {
    // 维保批次列表
    return axios.post(base.dl + "maintenanceBath/pages", params);
  },
  updatePerson(params) {
    // 维保批次列表
    return axios.post(base.dl + "maintenanceBath/man", qs.stringify(params));
  },
  delBathOne(id) {
    // 删除维保批次
    return axios.delete(base.dl + "maintenanceBath/one/" + id);
  },
  maintainPlanPage(params) {
    // 电梯保养计划列表
    return axios.post(base.dl + "maintenancePlan/elevator/pages", params);
  },
  delMaintainPlan(id) {
    // 删除电梯保养计划
    return axios.delete(base.dl + "maintenancePlan/" + id);
  },
  addMaintainPlan(params) {
    // 新增电梯保养计划
    return axios.post(base.dl + "maintenancePlan/new", params);
  },
  editMaintainPlan(params) {
    // 编辑电梯保养计划
    return axios.put(base.dl + "maintenancePlan/update", params);
  },
  importMaintain(params) {
    // 导入电梯维保计划"
    return axios.post(base.dl + "maintenancePlan/import", params);
  },
  exportMaintain(params) {
    // 导出电梯维保计划"
    return axios.post(base.dl + "maintenancePlan/download", params, {
      responseType: "blob",
    });
  },
  handover(params) {
    // 工作交接
    return axios.put(
      base.dl +
        "maintenancePlan/work/handover?nowWorkerId=" +
        params.nowWorkerId +
        "&previousWorkerId=" +
        params.previousWorkerId
    );
  },
  getAnnualData(params) {
    // 年检管理
    return axios.get(base.dl + "elevator/annual/pages", { params: params });
  },
  getAnnualDetail(id) {
    //年检管理详情
    return axios.get(base.dl + "elevator/annual/" + id);
  },
  yearUpdate(params) {
    // 年检更新
    return axios.post(base.dl + "elevator/annual", params);
  },
  updateInspectionReport(params) {
    // 更新自检报告
    return axios.post(
      base.dl +
        "elevator/annual/ins?id=" +
        params.id +
        "&inspectionReport=" +
        params.inspectionReport
    );
  },
  getAnnualChart(params) {
    // 年检管理一览表
    return axios.post(
      base.dl +
        "elevator/annual/charts?city=" +
        params.city +
        "&projectName=" +
        params.projectName +
        "&province=" +
        params.province
    );
  },
  getResultStatic(params) {
    // 年度台量统计
    return axios.get(base.dl + "chart/data/month", {
      params: params,
    });
  },
  getCountYearInner(params) {
    // 年度工单类型统计
    return axios.get(base.dl + "chart/year/pie/inner", {
      params: params,
    });
  },
  getCountYearOuter(params) {
    // 年度工单类型统计
    return axios.get(base.dl + "chart/year/pie/outer", {
      params: params,
    });
  },
  getCountMonthInner(params) {
    // 月份工单类型统计
    return axios.get(base.dl + "chart/month/pie/inner", {
      params: params,
    });
  },
  getCountMonthOuter(params) {
    // 月份工单类型统计
    return axios.get(base.dl + "chart/month/pie/outer", {
      params: params,
    });
  },
  getFaultStatic(params) {
    // 故障次数统计
    return axios.post(base.dl + "chart/station", params);
  },
  getBillStatic() {
    // 保养台量统计
    return axios.get(base.dl + "chart/region");
  },
  getBillStaticDetail(params) {
    // 保养台量统计详情
    return axios.get(base.dl + "chart/region/details", {
      params: params,
    });
  },
  getBillChartStatic(params) {
    // 保养情况一览表
    return axios.get(base.dl + "chart/maintenance", {
      params: params,
    });
  },
  getFaultTypeChart() {
    // 电梯故障情况一览表
    return axios.get(base.dl + "chart/breakdown/type");
  },
  getFaultReasonChart() {
    // 故障原因分析一览表
    return axios.get(base.dl + "chart/breakdown/reason");
  },
  getInspectStatic(params) {
    // 工单合格率图
    return axios.post(base.dl + "chart/inspect/status", params);
  },
  getInspectReasonChart(params) {
    // 工单不合格原因分析表
    return axios.post(base.dl + "chart/inspect/reason", params);
  },
  exportInspectReason(params) {
    // 图表分析-工单不合格原因分析表导出
    return axios.post(base.dl + "chart/inspect/reason/export", params, {
      responseType: "blob",
    });
  },
  getResponseStatus(params) {
    // 维修工单相应合格率
    return axios.post(base.dl + "chart/inspect/response/status", params);
  },
  maintenancePlanInspect(params) {
    // 维保计划核查列表
    return axios.get(base.dl + "maintenancePlan/inspect", {
      params: params,
    });
  },
  exportPlanInspect(params) {
    // 导出维保计划核查
    return axios.post(base.dl + "maintenancePlan/inspect/download", params, {
      responseType: "blob",
    });
  },
  updatenspect(params) {
    // 维保计划核查
    return axios.get(base.dl + "maintenancePlan/inspect/" + params.planId, {
      params: params,
    });
  },
  getAuditItem(params) {
    // 核查条件-获取维保项次
    return axios.get(base.dl + "maintenanceProjectConfig/audit/items", {
      params: params,
    });
  },
  getWorkBillInspectConfig(params) {
    // 核查条件-配置项
    return axios.get(base.dl + "workBillInspectConfig/items", {
      params: params,
    });
  },
  editWorkBillInspectConfig(params) {
    // 编辑核查条件-配置项
    return axios.put(base.dl + "workBillInspectConfig/" + params.id, params);
  },
  addWorkBillInspectConfig(params) {
    // 新增核查条件-配置项
    return axios.post(base.dl + "workBillInspectConfig/item", params);
  },
  delWorkBillInspectConfig(id) {
    // 删除核查条件-配置项
    return axios.delete(base.dl + "workBillInspectConfig/" + id);
  },
};

export default maintenance;
