<template>
  
  <jl-header v-if="header_show" />
  <audio  hidden controls loop ref="audioMessage" id="audioMessage"  src="//joylivecdn.oss-cn-shanghai.aliyuncs.com/mp3/12488.mp3
"/>
  <router-view v-slot="{ Component }">
    <keep-alive :include="keepAliveList">
      <component :is="Component" @public_header="getMessage" />
    </keep-alive>
    <!-- <component :is="Component" v-if="!$route.meta.keepAlive" @public_header="getMessage" /> -->
  </router-view>
  <!-- <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" @public_header="getMessage" /> -->
  <jl-footer v-if="header_show" />
  <!-- <el-dialog  :title="直播" v-model="dialogVisible2" width="40%"
      :before-close="handleClose" destroy-on-close>
    </el-dialog> -->
</template>
<script>
import { ElNotification } from "element-plus";
import { reactive, toRefs, watch, getCurrentInstance } from "vue";
import jlHeader from "./components/head";
import jlFooter from "./components/foot";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
// import { useRoute } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import protobuf from 'protobufjs';
import protoRoot from "@/proto.js";
import { file } from "@babel/types";
import { useI18n } from "vue-i18n";

// let root =await load("notify.proto",{keepCase: true});



export default {
  name: "App",
  components: {
    jlHeader,
    jlFooter,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      // dialogVisible2:true,
      header_show: false,
      stompClient: "",
      timer: "",
      message: localStorage.getItem("message") || {},
      messageList: JSON.parse(localStorage.getItem("messageList")) || [], // 消息列表 只保存当前登录下的消息
      keepAliveList: [], // 缓存的页面
      
    });
    window['playMp3']  = () => {
      var ttsAudio = document.getElementById("audioMessage");
      ttsAudio.play();
    }
    window['pauseMp3']  = () => {
      var ttsAudio = document.getElementById("audioMessage");
      ttsAudio.pause();
    }
    

    watch(
      () => route.path,
      () => {
        let index = state.keepAliveList.findIndex(
          (item) => item === route.name
        );
        if (route.meta.keepAlive && index == -1) {
          state.keepAliveList.push(route.name);
        } else if (!route.meta.keepAlive && index > -1) {
          state.keepAliveList.splice(index, 1);
        }
        // playMp3();
        initData(route.path);
      }
    );

    const initData = (o) => {
      if (o == "/welcome" || o == "/common/login") {
        // 手机扫描/登录页面清除长连接
        if (state.stompClient.connected) {
          state.disconnect();
          clearInterval(state.timer);
        }
      } else {
        // getMsg();
        if (!state.stompClient.connected) {
          setTimeout(() => {
            connection();
          }, 2000);
        }
      }
    };

    const connection = () => {
      if (
        proxy.$defined.btnPermission("消息推送") &&
        localStorage.getItem("token")
      ) {
        // 登录状态，有消息推送权限
        // 建立连接对象
        // let socket = new SockJS(
        //   "http://10.1.2.35:8021/websocket"
        // );
        let socket = new SockJS(
          // "https://iot.joylive.com/joycloud-pop/websocket"
          "http://106.14.36.215:31021/websocket"
        );
        // 获取STOMP子协议的客户端对象
        state.stompClient = Stomp.over(socket);
        // 定义客户端的认证信息,按需求配置
        let headers = {
          Authorization: localStorage.getItem("token"),
        };
        // 向服务器发起websocket连接
        state.stompClient.connect(
          headers,
          () => {
            state.stompClient.subscribe(
              `/queue/fault/${localStorage.getItem("username")}`,
              (msg) => {
                 
                // 订阅服务端提供的某个topic
                let Notify = protoRoot.lookupType("Notify");
                const rs = window.atob(msg.body);
                
                // console.log(rs);
                  const fileReader = new FileReader();
                  // fileReader.readAsArrayBuffer(rs);
                  let blob = new Blob([rs],{type:'text/plain;charset=utf-8'});
                  fileReader.readAsArrayBuffer(blob);

                  fileReader.onload = function(e){
                    // console.log(e.target.result);
                    const u8 = new Uint8Array(e.target.result);
                    const data = Notify.decode(u8);
                    console.log(data);
                    state.message = data;
                    state.messageList.push(data);
                    localStorage.setItem("message",data);
                    localStorage.setItem(
                      "messageList",
                      JSON.stringify(state.messageList)
                    );
                    getMsg(); // msg.body存放的是服务端发送给我们的信息
                  }
                  
                
                
                
                // console.log(data.Notify.content);
                // load("NotifyDto.proto").then((err,root)=>{
                //   console.log("root",root);
                //   console.log("error",err);
                //   const MyMessageType = root.lookupType("com.joylive.iot.ws.domain.MessageDto");
                //   const decodeData = MyMessageType.decode(msg.data);
                //   let data = JSON.stringify(decodeData);
                //   console.log(data);
                  
                // })
                
              },
              headers
            );
            state.stompClient.send(
              "/app/hello",
              headers,
              JSON.stringify({ sender: "", chatType: "JOIN", msg: "测试" })
            );
            // 用户加入接口
          },
          (err) => {
            // 连接发生错误时的处理函数
            console.log("失败");
            console.log(err);
            // setTimeout(() => {
            //     state.connection();
            // },2000)
          }
        );
      } else {
        disconnect();
      }
    };

    const disconnect = () => {
      // 断开连接
      if (state.stompClient) {
        state.stompClient.disconnect();
      }
    };

    const getMsg = () => {
      // 消息提醒
      // ElNotification.closeAll();
      if (state.header_show && localStorage.getItem("token")) {
        if (state.message != undefined) {
          playMp3();
          var html =
            "<p class='title'>故障提示：<span>" +
            state.messageList.length +
            "</span>条未处理</p><div class='flex'>" +
            "<div class='list'>";
          // for (var i = 0; i < (state.messageList.length<=5?state.messageList.length:5); i++) {
          //     // console.log(state.messageList[i]);
          //   html +=
          //     "<div class='item'><p>" +
          //     parseInt(i + 1) +
          //     "," +
          //     state.messageList[i].elevatorNumber +
          //     "电梯于" +
          //     state.messageList[i].faultTime +
          //     "时间报" +
          //     state.messageList[i].content +
          //     "故障，请立刻处置！</p><el-button plain onclick='dealFaultMsg("+
              
          //     "\""+
          //     state.messageList[i].id+
          //      "\",\""+
          //     state.messageList[i].url+
          //     "\")'>" +
          //     t("i18n.view") +
          //     "</el-button>";
          //     ElNotification({
          //       type: 'warning',
          //       position: "bottom-right",
          //       offset: 40,
          //       duration: 0,
          //       title: "普通故障",
          //       customClass: "workMessage",
          //       // dangerouslyUseHTMLString: true,
          //       message: state.messageList[i].elevatorNumber +
          //               "电梯于" +
          //               state.messageList[i].faultTime +
          //               "时间报" +
          //               state.messageList[i].content +
          //               "故障，请立刻处置！",
          //       onClose() {
          //         // 关闭通知，清除数据
          //         localStorage.removeItem("messageList");
          //       },
          // });

          // }
          ElNotification({
                type: 'warning',
                position: "bottom-right",
                offset: 40,
                duration: 0,
                title: "普通故障",
                customClass: "workMessage",
                // dangerouslyUseHTMLString: true,
                message: state.message.elevatorNumber +
                        "电梯于" +
                        state.message.faultTime +
                        "时间报" +
                        state.message.content +
                        "故障，请立刻处置！",
                onClose() {
                  // 关闭通知，清除数据
                  pauseMp3();
                  localStorage.removeItem("message");
                },
                onClick() {
                  
                      console.log("dfsdfasdfa");
                    let routeData = router.resolve({
                      path: "/realTime/detail?id=",
                      query: { flag: 1, id: state.message.url },
                    });
                    window.open(routeData.href, "_blank");
                    this.close();
                }
          });
          html += "</div></div></div>";
          
          // window.dealFaultMsg = state.dealFaultMsg;
        }
      }
    };

    const getMessage = (val) => {
      console.log(val,"Valvalvalvlavlal");
      state.header_show = val === true;
    };
    

    return {
      ...toRefs(state),
      getMessage,
    };
  },
};
</script>
<style lang="scss">
#productDistribution {
  .el-tabs {
    position: absolute;
    width: 4 * $width + 20;
    z-index: 99;
    top: 30px;
    background: $white;
    left: 31px;
    font-size: $font - 2;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs__content {
      max-height: 280px;
    }
    .list {
      padding: 5px;
      height: 250px;
      overflow-y: auto;
      .index,
      .item {
        display: inline-block;
        vertical-align: top;
      }
      .active {
        background: rgba(204, 204, 204, 0.5);
        color: #333;
      }
      > div {
        padding: 10px;
        cursor: pointer;
      }
      .item {
        padding-left: 10px;
      }
    }
  }
  .redDot,
  .yellowDot {
    width: 10px;
    height: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
  .redDot {
    background: #d02c2f;
    animation: scale 2s infinite;
  }
  .yellowDot {
    background: #fdbf01;
  }
  @keyframes scale {
    @for $i from 0 through 10 {
      #{$i * 10%} {
        transform: scale($i / 10);
        opacity: $i / 10;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #productDistribution {
    margin-top: -20px;
    .el-tabs {
      top: 10px;
      left: 10px;
      width: 300px;
    }
  }
}
</style>