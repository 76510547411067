/**
 * common模块接口列表
 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
// 根据需求是否导入qs模块
import qs from "qs";

const common = {
  menuApi() {
    // 菜单
    return axios.get(base.sq + "menu");
  },
  weatherApi(params) {
    // 天气
    return axios.get("https://www.tianqiapi.com/api/", {
      params: params,
    });
  },
  loginApi(params) {
    // 登录
    return axios.post(base.sq + "user/login", params);
  },
  getAccToken() {
    // 获取TOKEN
    return axios.post("https://open.ys7.com/api/lapp/token/get?appKey=7816a36a55ba4bbdb92fc5d32f3c594b&appSecret=68267f25adb25f25ad22519db823ab7f");
  },
  logoutApi(params) {
    // 登出
    return axios.post(base.sq + "user/logout", qs.stringify(params));
  },
  renewalDataApi() {
    // 数据同步
    return axios.post(base.dl + "data/renewal");
  },
  getUser(id) {
    // 获取用户信息
    return axios.get(base.sq + "user/" + id);
  },
  changePassword(params) {
    // 修改密码
    return axios.post(base.sq + "user/modifyPassword", params);
  },
  getTelCode(phoneNo) {
    // 获取手机验证码
    return axios.get(base.sq + "user/password/auth/" + phoneNo);
  },
  checkTelCode(phoneNo, authCode) {
    // 验证手机验证码
    return axios.get(
      base.sq + "/user/password/auth/" + phoneNo + "/" + authCode
    );
  },
  setNewPass(token, params) {
    // 设置新密码
    return axios.post(
      base.sq +
        "user/password/auth/" +
        token +
        "?confirmTokenPlain=" +
        params.confirmTokenPlain +
        "&passwordPlain=" +
        params.passwordPlain
    );
  },
  logData(params) {
    // 系统日志
    return axios.get(base.dl + "updateLog/log_all", {
      params: params,
    });
  },
  addLogData(params) {
    // 新增系统日志
    return axios.post(base.dl + "updateLog/one?content=" + params.content);
  },
  editLogData(id, params) {
    // 编辑系统日志
    return axios.put(
      base.dl + "updateLog/" + id + "?content=" + params.content
    );
  },
  delLogData(id) {
    // 删除系统日志
    return axios.delete(base.dl + "updateLog/" + id);
  },
  dealFaultMsg(id, params) {
    // 处理故障消息
    return axios.put(
      base.sw + "test/message/fault/" + id + "?message=" + params.message
    );
  },
  ignoreMsg(params) {
    // 忽略故障消息
    return axios.post(base.sw + "test/message/ignore", params);
  },
  defaultMsg(params) {
    // 默认消息
    return axios.get(base.sdl + "welcome", {
      params: params,
    });
  },
  setNote(params) {
    // 留言板-留言
    return axios.post(base.sq + "/message_board/note", params);
  },
  getReport(id) {
    // 获取报告数据
    return axios.get(
      "https://iot.joylive.com:8443/joycloud-statistic/statistic/statistics/detail/" +
        id
    );
  },
  getMenu() {
    // 获取菜单列表
    return axios.get(base.sq + "menu");
  },
  getMenuOne(id) {
    // 获取单个菜单
    return axios.get(base.sq + "menu/one/" + id);
  },
  addMenu(params) {
    // 新增菜单列表
    return axios.post(base.sq + "menu/one", params);
  },
  editMenu(params) {
    // 编辑菜单列表
    return axios.put(base.sq + "menu/one", params);
  },
  getPermission() {
    // 获取权限列表
    return axios.get(base.sq + "permission");
  },
  getPermissionOne(id) {
    // 获取单个权限
    return axios.get(base.sq + "permission/one/" + id);
  },
  addPermission(params) {
    // 新增权限列表
    return axios.post(base.sq + "permission/one", params);
  },
  editPermission(params) {
    // 编辑权限列表
    return axios.put(base.sq + "permission/one", params);
  },
  getLineageByCode(code) {
    // 字典类型-获取code下的所有字典项
    return axios.get(base.jl + "lineageType/one/" + code);
  },
};

export default common;
